/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Division from '../page-components/OurTeam/Division';

// Style
import '../page-styles/OurTeam.scss';
import { API_TEAM_GROUP } from '../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function OurTeam() {
  /* ********************************** HOOKS ********************************* */
  const [teamGroups, setTeamGroups] = React.useState();

  const { language } = useI18next();

  React.useEffect(() => {
    async function getTeam() {
      try {
        const { data } = await axios.get(API_TEAM_GROUP(language));
        setTeamGroups(data?.data);
      } catch {}
    }
    getTeam();
  }, [language]);
  // localisation
  const { t } = useTranslation();
  const { title } = t('ourTeam', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Our team" />
      <div className="our-team">
        <h1 className="our-team__title scroll-reveal">{title}</h1>
        <div className="our-team-sections">
          {teamGroups?.map((team, index) => (
            <Division
              division={team?.attributes}
              primary={index === 0}
              secondary={index > 0}
            />
          ))}
        </div>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["OurTeam", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default OurTeam;
