/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Card({ preTitle, title, link, description, imageUrl }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  /* ******************************** RENDERING ******************************* */

  return (
    <div className="card">
      <div className="card-img">
        <img src={`${imageUrl}`} alt={preTitle} />
      </div>
      <span className="card__pre-title">{preTitle}</span>
      <span className="card__title">{title}</span>
      <p className="card__description">{description}</p>
      {link && (
        <Link to={link} className="card__link">
          {link}
        </Link>
      )}
    </div>
  );
}

Card.propTypes = {
  preTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
};

Card.defaultProps = {
  link: '',
};

export default Card;
