/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SubTitle({ children }) {
  /* ******************************** RENDERING ******************************* */

  return <h2 className="sub-title">{children}</h2>;
}

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

SubTitle.defaultProps = {};

export default SubTitle;
