/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here
import Card from '../../../shared/UIKit/Card';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import SubTitle from '../../../shared/UIKit/SubTitle';
import { baseUrl } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Division({
  division: { name, team_members: teamMembers },
  primary,
  secondary,
}) {
  /* ******************************** CONSTANTS ******************************* */
  let typeOfSection = '';
  if (primary) {
    typeOfSection = 'primary';
  } else if (secondary) {
    typeOfSection = 'secondary';
  }
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  function Cards() {
    return teamMembers?.data
      ?.sort((a, b) => a?.attributes?.order - b?.attributes?.order)
      .map((member) => {
        if (member?.attributes?.displayLine) {
          return (
            <Col key={member?.id} lg={12} sm={12} md={12}>
              <Row justify="center">
                <Col
                  key={member?.id}
                  lg={4}
                  md={6}
                  sm={12}
                  className="card-container"
                >
                  <Card
                    preTitle={member?.attributes?.position}
                    title={member?.attributes?.name}
                    // link={link}
                    description={member?.attributes?.description}
                    imageUrl={
                      baseUrl + member?.attributes?.image?.data?.attributes?.url
                    }
                  />
                </Col>
              </Row>
            </Col>
          );
        }
        return (
          <Col
            key={member?.id}
            lg={4}
            md={6}
            sm={12}
            className="card-container"
          >
            <Card
              preTitle={member?.attributes?.position}
              title={member?.attributes?.name}
              // link={link}
              description={member?.attributes?.description}
              imageUrl={
                baseUrl + member?.attributes?.image?.data?.attributes?.url
              }
            />
          </Col>
        );
      });
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="our-team-division">
      <SubTitle
        className={`our-team-division__title ${typeOfSection} scroll-reveal`}
      >
        {name}
      </SubTitle>
      <Container fluid>
        <Row justify="center">
          <Cards />
        </Row>
      </Container>
    </Container>
  );
}

Division.propTypes = {
  division: PropTypes.objectOf(PropTypes.string, PropTypes.array.isRequired)
    .isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};

Division.defaultProps = {
  primary: false,
  secondary: false,
};

export default Division;
